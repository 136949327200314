/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 15, 2022 */



@font-face {
    font-family: 'd-din_condenseddinCnBd';
    src: url('d-dincondensed-bold-webfont.woff2') format('woff2'),
         url('d-dincondensed-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'd-din_condensedregular';
    src: url('d-dincondensed-webfont.woff2') format('woff2'),
         url('d-dincondensed-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'futura_ptdemi';
    src: url('futuraptdemi-webfont.woff2') format('woff2'),
    url('futuraptdemi-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'futura_ptbold';
    src: url('futuraptbold-webfont.woff2') format('woff2'),
    url('futuraptbold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}