html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 14px;
}

#root {
  width: 100%;
  height: 100%;
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
  font-family: inherit;
  line-height: inherit;
}

/* ****************************************************************************
MAP
**************************************************************************** */
.mapboxgl-control-container {
  display: none;
}

.marker {
  background-image: url('./assets/mapbox-marker-icon.svg');
  background-size: cover;
  width: 40px;
  height: 96px;
  cursor: pointer;
}

.marker-pos .pos {
  width: 23px;
  height: 23px;
  line-height: 23px;
  overflow: hidden;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  color: #fff;
  border: 2px #fff solid;
  margin: 8px auto 0 auto;
}

/* ****************************************************************************
DONATION WIDGET
**************************************************************************** */
#tamaro-widget {
  background: transparent !important;
}

#tamaro-widget .error-message {
  font-size: 1rem !important;
}

#tamaro-widget.tamaro-widget .payment-submit .main button {
  font-family: 'futura_ptdemi';
  border-radius: 0;
}

#tamaro-widget.tamaro-widget .payment-submit .main button .btn-text {
  font-size: 1.2rem;
  text-transform: uppercase;
}

:root {
  --tamaro-primary-color: #FC4C02;
  --tamaro-primary-color__hover: #FC4C02;
  --tamaro-overlay-header-color: #FFF;
  --tamaro-overlay-header-bg-color: #FC4C02;
  --tamaro-link-color: #FFF;
  --tamaro-primary-color__hover: #FC4C02;
  --tamaro-block-header-position-bg-color: #FC4C02;
  --tamaro-button-primary-color: #FFF;
  --tamaro-button-primary-bg-color: #FC4C02;
  --tamaro-button-primary-bg-color__hover: #FC4C02;
  --tamaro-button-primary-border-color__hover: darken(#FC4C02, 15%);
  --tamaro-bg-color: #000;
  --tamaro-text-color: #FFF;
  --tamaro-placeholder-color: #FFF;
  --tamaro-border-color: #FFF;
  --tamaro-icon-color: #FFF;
  --tamaro-block-header-position-color: #FFF;
  --tamaro-bg-color__hover: #5981A3;
}
